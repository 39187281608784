.ScrollLine {
    padding-top: 1vh;
    padding-bottom: 5vh;
    position: relative;
}

.firstLineTeacher {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}
.parallaxTeacher {
    overflow: hidden;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.parallaxTeacher .scroller {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    gap: 2rem;
}

.parallaxTeacher span {
    display: block;
}

.lineTeacher {
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: space-between;
    gap: 4rem;
}
.lineTeacher p {
    margin: 0;
    padding: 0;
    margin-left: 1.6rem;
}
.img-container {
    width: 200px;
    height: 200px;
    overflow: hidden;
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#VipHero ellipse {
    fill: theme("colors.blueVip.100");
    transition: all var(--transition-smooth) ease;
}
.darkmode #VipHero ellipse {
    fill: theme("colors.blueVip.950");
}
#VipHero path {
    fill: theme("colors.blueVip.600");
    transition: all var(--transition-smooth) ease;
}
.darkmode #VipHero path {
    fill: theme("colors.blueVip.300");
}
/* #dream path.toFill {
    fill: theme("colors.bluefr.400");
}
.darkmode #dream path.toFill {
    fill: theme("colors.bluefr.400");
} */

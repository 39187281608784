.TeacherScroll {
    padding-top: 8vh;
    padding-bottom: 10vh;
    position: relative;
}

.parallax {
    overflow: hidden;
    letter-spacing: -2px;
    box-sizing: border-box;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}

.firstLine {
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;

    display: flex;
    width: fit-content;
    white-space: nowrap;
    display: flex;
    gap: 4rem;
    flex-wrap: nowrap;
}

.parallax .lineContent p {
    margin: 0;
    padding: 0;
    font-family: Lama Sans;
}

.parallax .lineContent .Teacher-mask .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    mask-image: radial-gradient(circle(10rem at center), transparent 50%, black 100%);
}
